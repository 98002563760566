import React from "react"
import { graphql } from "gatsby"
import Layout from "../../wrappers/Layout"
import RichText from "../../components/RichText"
import Accordian from "../../components/Accordian"

const PageTemplate = ({ data, location }) => {

	const { title, copy, accordian } = data.page

	return (
		<Layout title={`${title} | Harrys London`} location={location}>
			<div className="align-center page-gutters innerx4">
				<h1>{title}</h1>
			</div>
			<article className="info-page page-gutters p2 u1 max-m mauto innerx4 has-links">
				<RichText content={copy} />
			</article>
			{accordian && (
				<div className="innerx2 has-links">
					{accordian.map((item, index) => <Accordian name={item.name} copy={item.copy} key={index} />)}
				</div>
			)}
		</Layout>
	)
}

export default PageTemplate

export const query = graphql`

	query($slug: String!) {
		page: contentfulPage(slug: { eq: $slug }) {
			title
			slug
			copy {
				raw
			}
			accordian {
				name
				copy: stockistsCopy {
					raw
				}
			}
		}
	}
`